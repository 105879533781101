import { gql } from "@apollo/client";


export const WasteReport_LineItemsFragment = gql`
	fragment WasteReport_LineItems on WasteReportFilter {
		lineItems {
			date
			annualized
			material { id name groupName }
			totalWeightReusedPounds
			totalWeightDonatedPounds
			totalWeightRecycledPounds
			totalWeightCompostedPounds
			totalWeightDivertedPounds
			totalWeightLandfilledPounds
			totalWeightIncineratedPounds
			totalWeightGeneratedPounds
		}
	}
`;
