import { gql } from "@apollo/client";

export const WasteTicket_LineItemsFragment = gql`
	fragment WasteTicket_LineItemsFragment on WasteTicket {
		id
		lineItems {
			id
			material { id name groupName }
			totalWeightReusedPounds
			totalWeightDonatedPounds
			totalWeightRecycledPounds
			totalWeightCompostedPounds
			totalWeightDivertedPounds
			totalWeightLandfilledPounds
			totalWeightIncineratedPounds
			totalWeightGeneratedPounds
		}
	}
`;