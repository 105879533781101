import { gql } from "@apollo/client";

export const WasteAudit_LineItemsFragment = gql`
	fragment WasteAudit_LineItemsFragment on WasteAudit {
		lineItems {
			date
			source
			container {
				id
				name
				container {
					id
					name
					iconUrl
				}
			}
			material {
				id
				name
			}
			totalWeightReusedPounds
			totalWeightDonatedPounds
			totalWeightRecycledPounds
			totalWeightCompostedPounds
			totalWeightDivertedPounds
			totalWeightLandfilledPounds
			totalWeightIncineratedPounds
			totalWeightGeneratedPounds
		}
	}
`;