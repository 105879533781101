import React from "react";
import { Box } from "grommet";
import { Outlet } from "react-router";
import { useWindowDimensions } from "../../hooks";
import { useMemo } from "react";
import { useMainContentHeight } from "../Main";
import { useAppSelector } from "../../store";
import { selectTheme } from "../../store/theme";

export interface SidebarLayoutProps {
	sidebar: JSX.Element;
	reverse?: boolean;
	widthOverride?: string;
}

export function useSidebarShrink(force?: boolean) {
	const { sizeIndex } = useWindowDimensions();
	const shrink = useMemo(() => !!force || sizeIndex < 2, [ force, sizeIndex ]);

	return shrink;
}

export const SidebarLayout: React.FC<SidebarLayoutProps> = (props) => {
	const shrink = useSidebarShrink();
	const collapse = useAppSelector(selectTheme).collapseSidebar;
	const mainContentHeight = useMainContentHeight();

	return (
		<Box direction={props.reverse ? "row-reverse" : "row"} flex id="layout-parent" height={`${mainContentHeight}px`}>
			<Box id="sidebar-container" width={shrink ? "0px" : (collapse) ? "100px" : props.widthOverride || "280px"}>
				{props.sidebar}
			</Box>
			<LayoutContainer>
				<Outlet />
			</LayoutContainer>
		</Box>
	);
};

export const LayoutContainer: React.FC<{
	children: React.ReactNode;
}> = (props) => {
	const mainContentHeight = useMainContentHeight();

	return (
		<Box flex id="layout-container" height={`${mainContentHeight}px`}>
			<Box flex id="scroll-container" style={{ display: "block", minHeight: `${mainContentHeight}px` }} overflow={{ vertical: "scroll" }}>
				{props.children}
			</Box>
		</Box>
	);
};